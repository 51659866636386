/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    array,
    string,
    object,
    bool,
    func,
} from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import GraphqlImoc from './Partials/GraphqlImoc/GraphqlImoc';
import GraphqlSubnav from './Partials/GraphqlSubnav/GraphqlSubnav';
import MultipleCTABanner from './Partials/MultipleCTABanner/MultipleCTABanner';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import CountDownTimerBanner from './Partials/GraphqlImoc/Partials/CountDownTimerBanner/CountDownTimerBanner';

const GraphqlCatHeaderContainer = ({
    categoryHeader,
    brand,
    samedayCutoff,
    featureFlagIsMobileCollectionTabbed,
    trackEvent,
}) => {
    const sameDayCollection = useSelector(getFeatureFlag('is-same-day-collection-v2'));
    const { whichSameDayCollectionWithConfig } = useFlags();
    const whichSameDayCollection = whichSameDayCollectionWithConfig?.type;
    const isSameDayCollectionV2 = sameDayCollection && whichSameDayCollection === 'variant';
    return (
        <>
            {categoryHeader.length !== 0 ? categoryHeader.map((block) => {
                const blockKey = Object.keys(block)[0];
                const blockObj = block[blockKey];
                switch (blockKey) {
                    case 'imoc':
                        return (
                            <GraphqlImoc
                                key={blockKey}
                                imocBlock={blockObj}
                                brand={brand}
                                samedayCutOff={samedayCutoff}
                                isSameDayCollectionV2={isSameDayCollectionV2}
                            />
                        );
                    case 'sub_navigation':
                        return (
                            <GraphqlSubnav key={blockKey} subnavBlock={blockObj} featureFlagIsMobileCollectionTabbed={featureFlagIsMobileCollectionTabbed} trackEvent={trackEvent} />
                        );
                    case 'banner_with_multiple_ctas':
                        return (
                            <MultipleCTABanner key={blockKey} multiCtaBlock={blockObj} />
                        );
                    case 'same_day_banner':
                        return (
                            <CountDownTimerBanner key={blockKey} sameDayBlock={blockObj} isSameDayCollectionV2={isSameDayCollectionV2} samedayCutOff={samedayCutoff} />
                        );
                    default:
                        return null;
                }
            }) : null}
        </>
    );
};

GraphqlCatHeaderContainer.propTypes = {
    samedayCutoff: object,
    categoryHeader: array.isRequired,
    brand: string.isRequired,
    featureFlagIsMobileCollectionTabbed: bool.isRequired,
    trackEvent: func.isRequired,
};

GraphqlCatHeaderContainer.defaultProps = {
    samedayCutoff: {},
};

export default GraphqlCatHeaderContainer;
